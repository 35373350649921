:root {
  --off-white: #fafafa;
  --pro-blue: rgb(38, 103, 255);
}
h1.fromLeft {
  display: inline-block !important;
}

h1.fromLeft:after {
  display: block;
  content: "";
  border-bottom: solid 3px #212529;
  transform: scaleX(0);
  transition: transform 250ms ease-in-out;
  transform-origin: 0% 50%;
}

*::-moz-selection {
  background: #f6a6ff;
  color: white;
}
*::selection {
  background: #f6a6ff;
  color: white;
}

.section-dark h1:after {
  border-bottom: solid 3px #fafafa !important;
}
h1:hover:after {
  transform: scaleX(1);
}
h1.fromRight:after {
  transform-origin: 100% 50%;
}

a {
  text-decoration: none !important;
}

.footer {
  background-color: var(--pro-blue);
  position: static;
  left: 0;
  bottom: 0;
  width: 100%;
  color: rgba(0, 0, 0, 0.5);
  text-align: right;
  font-size: 11px;
  font-family: MONOSPACE;
}

.nav {
  background-color: var(--off-white);
  position: sticky;
  top: 0px;
  width: 100%;
  height: 80px;
  z-index: 1000;
  box-shadow: 0 4px 14px 0 rgba(0, 0, 0, 0.15);
}

.nav-logo {
  height: calc(204px / 4);
  width: calc(1570.67px / 4);
  animation: pop 0.3s linear 1;
  min-width: 25%;
}

.nav-logo:hover {
  cursor: pointer;
  animation: pulse 0.85s ease-in infinite;
  filter: contrast(125%);
}

.nav .nav-content {
  max-width: 900px;
  padding: 0rem 1.5rem;
  margin: 0 auto;
  display: flex;
  flex-direction: row;
  overflow: hidden;
  justify-content: space-between;
  align-items: center;
  height: 100%;
}

.nav ul {
  display: flex;
  flex-direction: row;
  margin: 0;
  padding: 0;
}

.nav ul li {
  list-style: none;
  flex-grow: 1;
  text-align: center;
}

.nav ul li a {
  display: block;
}

.nav-item {
  flex-grow: 1;
  list-style: none;
  margin-left: 1.25rem;
  color: #333;
}

.nav-item:hover {
  color: rgb(38, 103, 255);
  cursor: pointer;
}

.nav-item > .active {
  color: #333;
  border-bottom: 1px solid #333;
}

.section {
}

.section-dark {
  background:  var(--pro-blue);
  color: white;
}

.section-content {
  max-width: 800px;
  margin: 0 auto;
  padding: 40px;
}

#section3 {
  max-width: 1000px;
}

#hookline {
  list-style-image: url("https://cdn.glitch.me/dad08474-c765-4bb4-a862-08c6e5f18652/star-stroke16x16.svg?v=1640810628108");
  font-family: "Open Sans", sans-serif;
}

#hookline li:hover {
  animation: pop 0.3s linear 1;
}

.hooklineTrigger {
  animation: pop 0.3s linear 1;
}

#pic {
  height: 200px !important;
  width: 200px !important;
}

#pic:hover {
  animation: pop 0.3s linear 1;
}

.form-check input:checked + label {
  font-weight: 500;
}

.form-check-input:checked {
  background-color: green !important ;
}

.btn-discord {
  background-color: #5865f2 !important;
  border-color: #5865f2 !important;
}

.formError {
  color: red;
  font-weight: light;
}

.checkmark__circle {
  stroke-dasharray: 166;
  stroke-dashoffset: 166;
  stroke-width: 2;
  stroke-miterlimit: 10;
  stroke: #7ac142;
  fill: none;
  animation: stroke 0.6s cubic-bezier(0.65, 0, 0.45, 1) forwards;
}

.checkmark {
  width: 56px;
  height: 56px;
  border-radius: 50%;
  display: block;
  stroke-width: 2;
  stroke: #fff;
  stroke-miterlimit: 10;
  margin: 1% auto;
  box-shadow: inset 0px 0px 0px #7ac142;
  animation: fill 0.4s ease-in-out 0.4s forwards,
    scale 0.3s ease-in-out 0.9s both;
}

.checkmark__check {
  transform-origin: 50% 50%;
  stroke-dasharray: 48;
  stroke-dashoffset: 48;
  animation: stroke 0.3s cubic-bezier(0.65, 0, 0.45, 1) 0.8s forwards;
}

/**************
  Keyframes
***************/

@keyframes rotation {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(359deg);
  }
}

@keyframes stroke {
  100% {
    stroke-dashoffset: 0;
  }
}
@keyframes scale {
  0%,
  100% {
    transform: none;
  }
  50% {
    transform: scale3d(1.1, 1.1, 1);
  }
}
@keyframes fill {
  100% {
    box-shadow: inset 0px 0px 0px 30px #7ac142;
  }
}

@keyframes pop {
  50% {
    transform: scale(1.1);
  }
}

@keyframes pulse {
  25% {
    transform: scale(0.9);
  }
  75% {
    transform: scale(1.1);
  }
}

@keyframes push {
  50% {
    transform: scale(0.8);
  }
}
